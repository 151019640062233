import { mapGetters } from 'vuex';

let pageMobile = null
let pageDesktop = null

export default {
    computed: {
        ...mapGetters(['isDeviceDesktop'])
    },
    created() {
        // Берём шаблоны из свойства templates у компонента
        const { mobile, desktop } = this.$options.templates

        if (mobile && desktop) {
            pageMobile = mobile
            pageDesktop = desktop
        }
    },
    render(h) {
        // Подключаем подходящий шаблон, в зависимости от девайса
        return this.isDeviceDesktop ? h(pageDesktop) : h(pageMobile)
    }
}

<i18n>
{
    "ru": {
        "title": "Актуальное",
        "best_articles": "Лучшие статьи",
        "near_events": "Ближайшие мероприятия",
        "project_news": "Новости Aromo",
        "news": "Новости",
        "all": "Все",
        "rubrics": "Рубрики",
        "description": "Обзоры ароматов, ответы на самые популярные вопросы о парфюмерии, истории брендов, секреты парфюмерного производства, биографии, интервью и другие инсайты парфюмерной индустрии",
        "write": "Написать",
        "editor": "Редакторские",
        "best_autors": "Лучшие авторы",
        "another_rubrics": "Другие рубрики",
        "events": "События",
        "show_all": "Показать все мероприятия",
        "pop_tags": "Популярные теги",
        "pop_tags_description": "Теги, которыми чаще всего отмечаются новости и статьи редакции и пользователей Aromo. Подписывайтесь на интересные вам теги, чтобы получать уведомления о выходе новых материалов с их упоминанием.",
        "read_all": "Все новости рубрики",
        "all_tags": "Все теги",
        "reader": "Читательские",
        "page": "Страница",
        "meta": {
            "title": "Новости парфюмерии 2021 на aromo.ru",
            "description": "Ежедневные новости парфюмерии. Читай и комментируй! Подписывайся, чтобы получать свежие новости каждый день и быть в курсе!"
        }
    }
}
</i18n>
<template>
    <div class="news-page">

        <div class="news-page__block">
            <Breadcrumb
                :items="breadcrumbs"
                class="news-page__breadcrumbs"/>

            <SectionCard
                class="news-page__section-card"
                :loaded="loaded">

                <template #main>

                    <PageBlock
                        content-visible
                        :max-rows="10"
                        :title="getTitle"
                        :heading-tag="'h1'">

                        <template #title-button>
                            <ButtonBase
                                v-if="isUserEditor"
                                shadowed
                                size="small"
                                @click.native="showEditor">
                                {{ $t('write') }}
                            </ButtonBase>
                        </template>

                        <BaseTabs
                            only-click
                            class="news-page__tabs"
                            @change="onTabChange">

                            <BaseTab
                                id="all"
                                :selected="isTabSelected('')"
                                :uniqueId="false"
                                :name="$t('all')"
                                link="/news/">

                                <template v-if="articlesList.total && isTabSelected('')">

                                    <div class="pre-placeholder" 
                                        v-if="popularArticles.length"
                                        :style="'background-image: url('+ get(popularArticles, '[0].cover.url') +')'">
                                        <PageGallery
                                            autoplay
                                            loop
                                            picture-property="cover"
                                            class="news-page__gallery"
                                            show-dots
                                            show-navigation
                                            transparent-dots
                                            :items="popularArticles"/>
                                    </div>

                                    <PostsGrid
                                        class="news-page__posts"
                                        :list="articlesList"
                                        :single-post="$parent.singlePost">

                                        <div slot="wide"
                                            v-if="recommended && recommended.length"
                                            class="news-page__subscribe-wrap">

                                            <CollectionsList :items="recommended"/>
                                        </div>

                                        <div slot="subscribe"
                                            class="news-page__subscribe-wrap">
                                            <BlockSubscribe />
                                        </div>

                                    </PostsGrid>
                                </template>
                            </BaseTab>
                            <BaseTab
                                v-for="rubric in rubrics"
                                :key="rubric.code"
                                :selected="isTabSelected(rubric.code)"
                                :id="rubric.code"
                                :link="$options.filters.urlRelative(rubric.htmlUrl)"
                                :uniqueId="false"
                                :name="rubric.name">

                                <PostsGrid
                                    :empty="isTabSelected(rubric.code)"
                                    v-if="articlesList.total && isTabSelected(rubric.code)"
                                    class="news-page__posts"
                                    :single-post="$parent.singlePost"
                                    :list="articlesList">

                                    <div slot="wide"
                                        v-if="recommended && recommended.length"
                                        class="news-page__subscribe-wrap">

                                        <CollectionsList :items="recommended"/>
                                    </div>

                                    <div slot="subscribe"
                                        class="news-page__subscribe-wrap">
                                        <BlockSubscribe />
                                    </div>

                                </PostsGrid>
                            </BaseTab>
                        </BaseTabs>


                    </PageBlock>
                </template>
            </SectionCard>

            <!-- <PageBlock
                :title="$t('near_events')">

                <EventsList :items="events" />

                <div class="page-link">
                    <div class="page-link__content">
                        <html-link
                            class="page-link__link"
                            url="https://stage.aromo.ru">
                            {{ $t('show_all') }}
                            <svg-icon name="chevron-right" class="page-link__icon" />
                        </html-link>
                    </div>
                </div>
            </PageBlock> -->

            <PageBlock
                class="news-page__events"
                big-title
                content-visible
                v-if="events && events.length && !isTabSelected('events')"
                :title="$t('events')">

                <template #title-button>
                    <html-link
                        @click.native="scrollToHead"
                        class="news-page__link"
                        url="https://stage.aromo.ru/news/events/">{{$t('read_all')}}</html-link>
                </template>

                <PostsGrid
                    no-pagination
                    is-free
                    :items="events" />

            </PageBlock>

            <PageBlock
                class="news-page__project-news"
                big-title
                content-visible
                v-if="projectNews && projectNews.length && !isTabSelected('aromonews')"
                :title="$t('project_news')">

                <template #title-button>
                    <html-link
                        @click.native="scrollToHead"
                        class="news-page__link"
                        url="https://stage.aromo.ru/news/aromonews/">{{$t('read_all')}}</html-link>
                </template>

                <PostsGrid
                    no-pagination
                    hide-slots
                    :items="projectNews" />

            </PageBlock>

            <PageBlock
                content-visible
                class="news-page__tags"
                big-title
                :tooltip-title="$t('pop_tags')"
                :tooltip-content="$t('pop_tags_description')"
                :title="$t('pop_tags')">

                <template #title-button>
                    <html-link
                        rel="nofollow"
                        @click.native="scrollToHead"
                        class="news-page__link"
                        to="/tags">{{$t('all_tags')}}</html-link>
                </template>

                <TagsList :items="bestTags"/>
            </PageBlock>
            <PageBlock
                content-visible
                big-title
                class="news-page__rubrics"
                :title="$t('rubrics')">
                <RubricsList :items="rubrics"/>
            </PageBlock>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get'
import mixin from '@/pages/news/mixins/'

export default {
    name: 'NewsDesktop',
    mixins: [mixin],
    methods: {
        get,
    }
}
</script>

<script>
import get from 'lodash/get'
import url from '@/filters/url'
import './style.less'

import PageMobile from '@/pages/news/mobile/'
import PageDesktop from '@/pages/news/desktop/'

import RenderMixin from '@/mixins/render.mixin'
import UserLikes from '@/mixins/userLikes.mixin'

import News from '@/models/news/'
import Section from '@/models/articles/Section'

export default {
    name: 'NewsIndex',
    mixins: [ RenderMixin, UserLikes ],
    globalBus: {
        'auth-complete'() {
            this.$fetch()
        }
    },
    fetchOnServer: false,
    asyncData({ store, params, $auth, app, query, route, error }) {
        let requests = []
        let parseResult = null

        const rubricCode = params.rubric
        const tagsCount = store.getters.isDeviceDesktop ?  20 : 10

        const baseUrl = app.i18n.__baseUrl
        const page = url.getPageNumber(route.path);

        const catalogHref = app.router.resolve({
            name: 'News'
        }).href

        const articleModel = new News().where('per_page', 26);

        if (rubricCode) {
            const sectionApi = new Section().custom(articleModel, 'sections')
            const sectionModel = new Section().custom(articleModel, 'sections', params.rubric)
            const sectionArticleModel = new Section().custom(articleModel, 'sections', params.rubric, articleModel)

            requests = [
                sectionModel.get(),
                sectionArticleModel.where('per_page', 26).where('page', page).get(),
                articleModel.bestTags().where('per_page', tagsCount).get(),
                sectionApi.get(),
                articleModel.relatedPages().get(),
                articleModel.recommended().get(),
                (new News).where('section_code', 'aromonews').where('per_page', 3).get(),
                (new News).where('section_code', 'events').where('per_page', 4).get(),

            ];

            parseResult = async results => {
                const section = get(results, '[0][0]')
                const articlesList = get(results, '[1][0]')
                const bestTags = get(results, '[2][0].items')
                const rubrics = get(results, '[3][0].items')
                const relatedPages = results[4];
                const recommended = results[5];
                const projectNews = get(results, '[6][0].items')
                const events = get(results, '[7][0].items')

                // Получаем пост со следующей страницы для блока подписки
                let singlePost = {}
                if (articlesList.lastPage > 1 && store.getters.isDeviceDesktop) {
                    const postResult = await sectionArticleModel.where('page', 27).where('per_page', 1).get()
                    singlePost = get(postResult, '[0].items[0]');
                }

                const breadcrumbs = [
                    {
                        path: baseUrl,
                        name: app.i18n.t('breadcrumbs.perfumes'),
                    },
                    {
                        path: baseUrl + catalogHref,
                        name: app.i18n.t('breadcrumbs.news'),
                    },
                    {
                        path: baseUrl + catalogHref + section.code,
                        name: section.name,
                    },
                ]

                if (process.server) {
                    app.store.dispatch('rest/headers/filterLastModified', {
                        url: [`/news`, `/news/recommended`, `/news/best-tags`]
                    });
                }

                return {
                    section,
                    events,
                    projectNews,
                    articlesList,
                    rubrics,
                    recommended,
                    bestTags,
                    singlePost,
                    relatedPages,
                    breadcrumbs,
                    nextPage: get(results, '[1][0].nextPage', null),
                    prevPage: get(results, '[1][0].prevPage', null),
                    currentPage: get(results, '[1][0].currentPage', null)
                }
            }
        } else {

            requests = [
                (new News).where('page', page).where('per_page', 26).get(),
                (new News).where('actual', 'y').where('per_page', 26).get(),
                (new News).where('section_code', 'aromonews').where('per_page', 3).get(),
                (new News).where('section_code', 'events').where('per_page', 4).get(),
                articleModel.rubrics().get(),
                articleModel.bestTags().where('per_page', tagsCount).get(),
                articleModel.relatedPages().get(),
                articleModel.recommended().get(),
                // (new News).where('page', 27).where('per_page', 1).get(),
            ];

            parseResult = async results => {
                const articlesList = get(results, '[0][0]')
                const popularArticles = get(results, '[1][0].items')
                const projectNews = get(results, '[2][0].items')
                const events = get(results, '[3][0].items')
                const rubrics = get(results, '[4][0].items')
                const bestTags = get(results, '[5][0].items')
                const relatedPages = results[6];
                const recommended = results[7];
                // const singlePost = get(results, '[8][0].items[0]');

                let singlePost = {}
                if (articlesList.lastPage > 1 && store.getters.isDeviceDesktop) {
                    const postResult = await (new News).where('page', 27).where('per_page', 1).get()
                    singlePost = get(postResult, '[0].items[0]');
                }

                const breadcrumbs = [
                    {
                        path: baseUrl,
                        name: app.i18n.t('breadcrumbs.perfumes'),
                    },
                    {
                        path: baseUrl + catalogHref,
                        name: app.i18n.t('breadcrumbs.news'),
                    },
                ]

                if (process.server) {
                    app.store.dispatch('rest/headers/filterLastModified', {
                        url: [`/news`, `/news/recommended`, `/news/best-tags`]
                    });
                }

                return {
                    singlePost,
                    recommended,
                    popularArticles,
                    events,
                    projectNews,
                    articlesList,
                    rubrics,
                    bestTags,
                    relatedPages,
                    breadcrumbs,
                    nextPage: get(results, '[0][0].nextPage', null),
                    prevPage: get(results, '[0][0].prevPage', null),
                    currentPage: get(results, '[0][0].currentPage', null)
                }
            }
        }

        return Promise.all(requests)
            .then(parseResult)
            .catch((e) => {
                error({statusCode: e.response.status, message: e.response.data});
            });
    },
    data() {
        return {
            relatedPages: null,
            singlePost: {},
            recommended: null,
            loaded: false,
            articlesList: {},
            section: {},
            projectNews: [],
            events: [],
            rubrics: [],
            popularArticles: [],
            bestTags: [],
            nextPage: null,
            prevPage: null,
            currentPage: null
        }
    },
    beforeMount(){

        const allArticles = [
            ...get(this.articlesList, 'items', []),
            ...get(this.projectNews, 'items', []),
            ...get(this.popularArticles, 'items', [])
        ]

        this.getUserLikes(allArticles)
    },
    provide() {
        return this.$data
    },
    templates: {
        mobile: PageMobile,
        desktop: PageDesktop,
    }
}

</script>

<style lang="less">
@import "./style.less";
</style>

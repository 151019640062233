<template>
    <ul class="tags-list"
        :class="rootClasses">

        <li class="tags-list__item"
            v-for="tag in items"
            :key="tag.code">

            <html-link
                rel="nofollow"
                v-if="tag.htmlUrl && !closable"
                class="tags-list__link"
                :url="tag.htmlUrl"/>

            <span class="tags-list__name">
                {{ tag.name }}

                <span
                    class="tags-list__counter"
                    v-if="withCounter && (tag.count || tag.allItems)">{{ tag.count || tag.allItems.total }}</span>
            </span>

            <button
                class="tags-list__remove"
                v-if="closable"
                @click.prevent="onRemove(tag.code)">

                <svg-icon name="icon-close-black"/>

            </button>
        </li>

    </ul>
</template>

<script>
import includes from 'lodash/includes'

export default {
    name: 'TagsList',
    props: {
        size: {
            type: String,
            default: 'regular',
            validator(value) {
                return includes(['thin', 'small', 'regular'], value)
            }
        },
        withCounter: {
            type: Boolean,
            default: true
        },
        items: {
            type: Array,
            default: () => []
        },
        closable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        rootClasses() {
            return {
                'tags-list_small': this.size === 'small',
                'tags-list_thin': this.size === 'thin',
            }
        }
    },
    methods: {
        onRemove(code) {
            this.$emit('remove-tag', code)
        }
    }
}
</script>

<style lang="less">
.tags-list {
    display: flex;
    flex-flow: wrap;

    list-style: none;

    margin: 0;
    padding-top: rem(10);
    padding-bottom: rem(11);

    .page-padding();

    &__remove {
        background: none;
        border: none;
        outline: none;

        margin-left: rem(6);
        padding: 0;

        svg {
            height: rem(16);
            width: rem(16);
        }
    }

    &__name {
        line-height: 1.2;
        white-space: nowrap;

        padding-top: rem(1);
    }

    &__item {
        display: inline-flex;
        align-items: center;

        border: rem(1) solid @color-gray-white;
        border-radius: rem(3);
        font-size: rem(15);
        /* text-align: center; */
        /* white-space: nowrap; */

        margin-right: rem(8);
        margin-bottom: rem(9);
        padding: rem(9) rem(16);

        position: relative;

        user-select: none;

        @{desktop} & {
            margin-right: rem(12);
            margin-bottom: rem(12);

            transition: box-shadow 0.2s ease;

            &:hover,
            &:focus {
                @media @hover {
                    box-shadow: 0 rem(1) rem(8) rgba(0, 0, 0, 0.1);
                }
            }
        }
    }

    &__counter {
        color: @color-gray-light;

        margin-left: rem(6)
    }

    &__link {
        display: block;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    &_thin {
        padding: rem(16) 0;
    }

    &_thin &__item {
        font-size: rem(15);
        line-height: 1.5;

        padding: rem(8) rem(12);

        @{mobile} &,
        @{desktop} & {
            margin-right: rem(8);
            margin-bottom: rem(8);
        }
    }

    &_small {
        padding: rem(16) 0;

        @{desktop} & {
            padding-bottom: 0;
        }
    }

    &_small &__item {
        font-size: rem(14);
        line-height: rem(18);

        padding: rem(6) rem(16);
    }
}
</style>

<i18n>
{
    "ru": {
        "title": "Актуальное",
        "best_articles": "Лучшие статьи",
        "near_events": "Ближайшие мероприятия",
        "project_news": "Новости Aromo",
        "chained_pages": "Интересные подборки",
        "news": "Новости",
        "all": "Все",
        "rubrics": "Рубрики",
        "description": "Обзоры ароматов, ответы на самые популярные вопросы о парфюмерии, истории брендов, секреты парфюмерного производства, биографии, интервью и другие инсайты парфюмерной индустрии",
        "write": "Написать",
        "editor": "Редакторские",
        "best_autors": "Лучшие авторы",
        "another_rubrics": "Другие рубрики",
        "events": "События",
        "show_all": "Показать все мероприятия",
        "pop_tags": "Популярные теги",
        "pop_tags_description": "Теги, которыми чаще всего отмечаются новости и статьи редакции и пользователей Aromo. Подписывайтесь на интересные вам теги, чтобы получать уведомления о выходе новых материалов с их упоминанием.",
        "read_all": "Все новости рубрики",
        "all_tags": "Все теги",
        "reader": "Читательские",
        "page": "Страница",
        "meta": {
            "title": "Новости парфюмерии 2021 на aromo.ru",
            "description": "Ежедневные новости парфюмерии. Читай и комментируй! Подписывайся, чтобы получать свежие новости каждый день и быть в курсе!"
        }
    }
}

</i18n>
<template>
    <div class="news-page">
        <div class="news-page__block">
            <Breadcrumb
                :items="breadcrumbs"
                style="margin-bottom: 0;"
                class="news-page__breadcrumbs"/>

            <SectionCard :loaded="loaded">
                <template #main>
                    <PageBlock
                        class="news-page__block-top"
                        @select-click="showRubrics"
                        :title-selectable="isTitleSelectable"
                        :max-rows="10"
                        :description="getDescription"
                        :title="getTitle"
                        :heading-tag="'h1'">

                        <template #title-select>
                            {{ $t('rubrics') }}
                        </template>

                        <template #title-button>
                            <ButtonBase
                                v-if="isUserEditor"
                                @click.native="showEditor"
                                icon="edit-velvet"
                                :show-as-link="true">
                                {{ $t('write') }}
                            </ButtonBase>
                        </template>

                        <div class="pre-placeholder" 
                            v-if="popularArticles.length"
                            :style="'background-image: url('+ get(popularArticles, '[0].cover.url') +')'">
                            <PageGallery
                                loop
                                autoplay
                                v-if="popularArticles.length"
                                picture-property="cover"
                                class="news-page__gallery"
                                show-dots
                                transparent-dots
                                :items="popularArticles"/>
                        </div>

                        <PostsList
                            class="news-page__posts"
                            :list="articlesList">

                            <div
                                class="articles-page__content"
                                slot="content">

                                <CollectionsList
                                    v-if="recommended && recommended.length"
                                    :items="recommended"/>

                                <BlockSubscribe v-if="isShowSubscribe"/>

                                <BlockArticle
                                    class="articles-page__article"
                                    v-else-if="singlePost && singlePost.code"
                                    v-bind="getArticleProperties(singlePost)" />

                            </div>

                        </PostsList>

                    </PageBlock>
                </template>
            </SectionCard>
            <!-- <PageBlock -->
            <!--     :title="$t('near_events')"> -->

            <!--     <EventsList :items="events" /> -->

            <!--     <div class="page-link"> -->
            <!--         <div class="page-link__content"> -->
            <!--             <html-link -->
            <!--                 class="page-link__link" -->
            <!--                 url="https://stage.aromo.ru"> -->
            <!--                 {{ $t('show_all') }} -->
            <!--                 <svg-icon name="chevron-right" class="page-link__icon" /> -->
            <!--             </html-link> -->
            <!--         </div> -->
            <!--     </div> -->
            <!-- </PageBlock> -->

            <PageBlock
                v-if="events && events.length && !isTabSelected('events')"
                :title="$t('events')">

                <ArticlesList
                    class="news-page__events"
                    :articles="events"
                    type="news"/>

                <div class="news-page__page-link page-link">
                    <div class="page-link__content">
                        <html-link
                            class="page-link__link"
                            @click.native="scrollToHead"
                            url="https://stage.aromo.ru/news/events/">
                            {{ $t('read_all') }}
                            <svg-icon name="chevron-right" class="page-link__icon" />
                        </html-link>
                    </div>
                </div>
            </PageBlock>
            <PageBlock
                v-if="projectNews && projectNews.length && !isTabSelected('aromonews')"
                :title="$t('project_news')">

                <EventsList :items="projectNews" />
                <div class="news-page__page-link page-link">
                    <div class="page-link__content">
                        <html-link
                            class="page-link__link"
                            @click.native="scrollToHead"
                            url="https://stage.aromo.ru/news/aromonews/">
                            {{ $t('read_all') }}
                            <svg-icon name="chevron-right" class="page-link__icon" />
                        </html-link>
                    </div>
                </div>
            </PageBlock>
            <PageBlock
                class="news-page__tags"
                :tooltip-title="$t('pop_tags')"
                :tooltip-content="$t('pop_tags_description')"
                :title="$t('pop_tags')">
                <TagsList :items="bestTags"/>
                <div class="news-page__page-link page-link">
                    <div class="page-link__content">
                        <router-link
                            rel="nofollow"
                            class="page-link__link"
                            :to='{ name: "PopularTags" }'>
                            {{ $t('all_tags') }}
                            <svg-icon name="chevron-right" class="page-link__icon" />
                        </router-link>
                    </div>
                </div>
            </PageBlock>
            <PageBlock
                class="news-page__rubrics"
                :title="$t('rubrics')">
                <RubricsList :items="rubrics"/>
            </PageBlock>
            <PageBlock v-if="relatedPages && relatedPages.length" :title="$t('chained_pages')">
                <div class="perfume__page-block">
                    <LinkedPagesList :items="relatedPages"/>
                </div>
            </PageBlock>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get'
import mixin from '@/pages/news/mixins/'
import BlockArticle from '@/components/layout/blocks/BlockArticle'

export default {
    name: 'NewsMobile',
    components: {
        BlockArticle
    },
    mixins: [mixin],
    methods: {
        get,
    }
}
</script>

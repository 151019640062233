import { mapGetters } from 'vuex'
import get from 'lodash/get'

import Breadcrumb from '@/components/layout/nav/Breadcrumb'
import SectionCard from '@/components/layout/AppSectionCard';
import PageBlock from '@/components/layout/AppPageBlock';
import ButtonBase from '@/components/ui/buttons/ButtonBase';
import PageGallery from '@/components/layout/AppPageGallery';
import PostsList from '@/components/layout/blocks/PostsList';
import PostsGrid from '@/components/layout/blocks/PostsGrid';
import BlockSubscribe from '@/components/forms/BlockSubscribe'
import CollectionsList from '@/components/layout/blocks/CollectionsList'
import TagsList from '@/components/layout/blocks/TagsList';
import RubricsList from '@/components/layout/blocks/RubricsList';
import ArticlesList from '@/components/layout/blocks/ArticlesList/ArticlesList';
import LinkedPagesList from '@/components/layout/blocks/LinkedPagesList';
// import EventsList from '@/components/layout/blocks/EventsList';

export default {
    components: {
        BlockSubscribe,
        CollectionsList,
        ButtonBase,
        LinkedPagesList,
        // EventsList,
        ArticlesList,
        SectionCard,
        PageBlock,
        PageGallery,
        PostsList,
        PostsGrid,
        TagsList,
        RubricsList,
        Breadcrumb
    },
    data() {
        return {
            loaded: false,
            isShowSubscribe: true
        }
    },
    globalBus: {
        'fetch-complete'() {
            this.isShowSubscribe = !get(this.$auth, 'user.id', false)
        },
        'on-editor-show'() {
            this.showPopup('content-editor', {
                type: 'news'
            })
        }
    },
    inject: [
        'breadcrumbs',
        'section',
        'relatedPages',
        'recommended',
        'articlesList',
        'singlePost',
        'projectNews',
        'events',
        'rubrics',
        'popularArticles',
        'bestTags',
        'prevPage',
        'nextPage',
        'currentPage'
    ],
    mounted() {
        this.loaded = true
        window.addEventListener('popstate', this.onPopState);
    },
    beforeDestroy(){
        window.removeEventListener('popstate', this.onPopState);
    },
    computed: {
        isUserEditor() {
            return get(this.$auth, 'user.editor', false);
        },
        ...mapGetters(['isDeviceDesktop']),
        isTitleSelectable() {
            return !!get(this.$route, 'params.rubric')
        },
        getDescription() {
            return get(this.section, 'description')
        },
        getTitle() {
            const sectionName = get(this.section, 'name')

            return sectionName || this.$t('news')
        },
        metaDefault() {
            return {
                title: this.$t('meta.title'),
                description: this.$t('meta.description')
            };
        }
    },
    methods: {
        onPopState(){
            window.location.reload();
        },
        showEditor() {
            this.$authEmit('on-editor-show')
        },
        scrollToHead() {
            this.$scrollTo('html', 750)
        },
        isTabSelected(code) {
            const rubricCode = get(this.$route, 'params.rubric')

            if (!rubricCode && ((code === '') || (code === 'all'))) return true

            return rubricCode === code
        },
        getArticleProperties(article) {
            if (!article) return

            return {
                date: article.createdAt,
                source: article.source,
                code: article.code,
                name: this.getArticleName(article),
                picture: get(article, 'picture.url'),
                title: article.name,
                url: article.htmlUrl,
                userUrl: get(article, 'createdBy.htmlUrl', get(article, 'createdBy[0].htmlUrl', '')),
                section: article.section,
                description: article.anons,
                likes: get(article, 'likes.total'),
                comments: get(article, 'comments.total'),
                views: get(article, 'views.total')
            }
        },
        getArticleName(article) {
            return get(article, 'createdBy.name') ||
                get(article, 'createdBy[0].name', null)
        },
        onTabChange(value) {
            if (!value || this.isTabSelected(value)) return

            let params = {}

            if (value === 'all') {
                params = { name: 'News' }
            } else {
                params = {
                    name: 'NewsRubric',
                    params: {
                        rubric: value
                    }
                }
            }

            this.$router.push(params)
        },
        showRubrics() {
            this.showPopup('rubrics', {
                items: this.rubrics
            })
        },
        metaNextPage(){
            let url = this.$i18n.__baseUrl

            if(this.currentPage === 1){
                url += this.$route.path + 'page/' + this.nextPage + '/'
            }else{
                url += this.$route.path.replace(/\/page\/[0-9]+/, '/page/' + this.nextPage)
            }

            return url
        },
        metaPrevPage(){
            let url = this.$i18n.__baseUrl

            if(this.currentPage === 2){
                url += this.$route.path.replace(/\/page\/[0-9]+/, '')
            }else{
                url += this.$route.path.replace(/\/page\/[0-9]+/, '/page/' + this.prevPage)
            }

            return url
        },
        metaCanonical(){
            return this.$i18n.__baseUrl + this.$route.path.replace(/\/page\/[0-9]+/, '')
        }
    },
    head() {

        const metaData = get(this.section, 'meta')

        let title = get(metaData, 'title', this.metaDefault.title)

        if(this.currentPage > 1){
            if(!metaData)
                title = this.$t('news')

            title += ' | ' + this.$t('page') + ' ' + this.currentPage
        }

        const meta = {
            title,
            meta: this.$options.filters.metaApiExtract({meta: metaData}, this.metaDefault),
            link: [
                {
                    hid: 'i18n-can',
                    rel: 'canonical',
                    href: get(this.meta, 'canonical', this.metaCanonical())
                }
            ]
        }

        if(this.currentPage > 1)
            meta.meta.push({
                hid: 'robots',
                name: 'robots',
                content: 'noindex, follow'
            })

        if(!this.filtersCount){

            if(this.nextPage)
                meta.link.push(
                    {
                        rel: 'next',
                        href: this.metaNextPage()
                    }
                )

            if(this.prevPage)
                meta.link.push(
                    {
                        rel: 'prev',
                        href: this.metaPrevPage()
                    }
                )
        }

        return meta
    }
}
